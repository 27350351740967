import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAccessMedia,
  CBtnList,
  LContact,
} from '../../../components/_index';
import '../../../assets/_sass/page/access.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESS',
            sub: 'アクセス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '当ホテルについて',
                path: '/about/',
              },
            ],
            current: {
              label: 'アクセス',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <CAccessMedia
            data={[
              {
                map: {
                  src: '',
                },
                text: (
                  <>
                    <h2 className="c_headingLv2 u_tal">
                      横浜ロイヤルパークホテル
                    </h2>
                    <p>
                      〒220-8173　横浜市西区みなとみらい2-2-1-3
                      <br />
                      <a href="tel:0452211111">TEL 045-221-1111</a>（代表）
                    </p>
                  </>
                ),
                btnList: [
                  {
                    label: <>Google Maps</>,
                    link: {
                      href: 'https://goo.gl/maps/Vmq7tyCFpEfmRfTo6',
                      blank: true,
                    },
                    color: 'borderBlack',
                  },
                ],
              },
            ]}
          />
          <CBtnList
            exClass="p_aboutBtnList"
            align="left"
            data={[
              {
                label: '電車でお越しのお客様',
                link: {
                  href: '/about/access/train/',
                },
              },
              {
                label: '車でお越しのお客様',
                link: {
                  href: '/about/access/car/',
                },
              },
              {
                label: '空港からお越しのお客様',
                link: {
                  href: '/about/access/airplanemenu/',
                },
              },
              {
                label: 'ホテル発 直行バスのご案内',
                link: {
                  href: '/about/access/bus/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/facilities/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      {/* <LContact
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
